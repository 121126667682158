@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'FA Regular';
  src: url('assets/fonts/fa-regular-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FA Brands';
  src: url('assets/fonts/fa-brands-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FA Solid';
  src: url('assets/fonts/fa-solid-900.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


body {
  color: #474c6e;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Neurial Grotesk", system-ui, sans-serif;
}


@layer base {
  html {
    font-family: Neurial Grotesk, system-ui, sans-serif;
  }
}

@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .bg-radial-primary{
    background: rgb(20,90,255);
    background: radial-gradient(circle, rgba(20,90,255,1) 0%, rgba(0,55,185,1) 100%);
  }
}

@layer components {
  .btn {
    @apply font-medium px-4 py-2 rounded;
  }

  .btn:focus {
    @apply outline-none;
  }

  .btn-primary {
    @apply bg-primary text-white rounded shadow-lg transition duration-500 ease-in-out transform;
  }

  .btn-primary:hover {
    @apply bg-primary -translate-y-1;
  }

  .tooltip .tooltip-text {
    @apply invisible p-2 absolute z-50 inline-block;
  }

  .tooltip:hover .tooltip-text {
    @apply visible;
  }

}

;